import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, reactive, ref} from 'vue';
import {
  ACT_GET_ABILITY_BANK_LIST,
  ACT_GET_ABILITY_GRADE_LIST, ACT_GET_ABILITY_REQSUBJ_LIST,
  ACT_GET_ABILITY_REQUIREMENT_LIST,
  ACT_GET_ABILITY_RESULT,
  ACT_GET_MY_ABILITY_ETC_LSCN_LIST,
  ACT_GET_MY_ABILITY_SELL_LSCN, MUT_SET_ABILITY_REQSUBJ_LIST,
} from '@/store/modules/my/my';
import {getItem, getItems, lengthCheck, setParams} from '@/assets/js/util';
import {downloadAbilityGuideFiles} from '@/assets/js/modules/support/support-common';
import {getGradeScore} from '@/assets/js/modules/capability/capability-common';
import {downloadFileDownMng} from "@/assets/js/modules/common/filedown";
import {useAlert} from "@/assets/js/modules/common/alert";

export const capabilitySetup = () => {
  const store = useStore();
  const route = useRoute();
  const {showMessage} = useAlert();
  const isMobile = computed(() => store.state.auth.isMobile);
  const session = computed(() => store.state.auth.session);
  const currentView = computed(() => route.params.view ? route.params.view : 'result')
  const capabilityId = computed(() => {
    if (route.params.view === 'system') {
      return 'kb-adc2-r014';
    }
    return 'kb-adc2-r011';
  });
  const isMainActive = computed(() => store.state.support.main.show);
  const isFileAtchListShow = ref(false);

  const readies = reactive({
    result: false
    // course: false,
    // license: false,
    // requirement: false,
  });

  const result = reactive({
    ficjkwbh: '',
    ficjkgup: '',
    jikmuReqCount: 0,
    jkgupReqBegin: 0,
    jkgupReqGrade: 0,
    jkgupReqEtest: 0,
    selfScore: 0,
    totalScore: 0,
    ficbujum: '',
    ficjikmu: '',
    ficjkwee: '',
    fidhjgsg: '',
    fidhssbr: '',
    jikmuReqCountDefault: 0,
    jikmuReqDefCount: 0,
    modifyDate: null,
    cdpResultId: '',
    createDate: null,
    addscoreLic: 0,
    addscoreSales: 0,
    addscoreEtc: 0,
    pilsupassCnt: 0,
    jkgupGradeBookingId: 0,
    jkgupGradeLrnMapngRsltSumupSn: 0,
    preclassname: '',
    l1PromotionYn: '',
  });

  const courses = ref([]);

  // 직급 2자리까지만 적용
  const elements = computed(() => {
    const contains = [];
    const ficjkgup = result.ficjkgup && result.ficjkgup.length > 2 ? result.ficjkgup.substring(0, 2) : result.ficjkgup;
    if (['L0', 'L1', 'S1'].includes(ficjkgup)) {
      // S => 은실종
      contains.push('S');

      // L0 => L1 진급자
      if (result.l1PromotionYn === 'Y') contains.push('O');
    }

    if (['L2', 'L3', 'S2', 'S3'].includes(ficjkgup)) {
      // O => 신임
      contains.push('O');
    }

    // if (['L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {

    // 2023-02-20 구시스템 처럼 S 직군도 직무필수를 보여주지만 dash-line 처리
    if (['S1', 'S2', 'S3', 'L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {
      // R => 직무필수
      contains.push('R');
    }
    if (['L0', 'L1', 'L2', 'L3', 'S1', 'S2', 'S3'].includes(ficjkgup)) {
      // P => 승격예비
      contains.push('P');
    }

    return contains;

  });


  const getMyAbility = () => {
    store.dispatch(`my/${ACT_GET_ABILITY_RESULT}`).then(res => {
      if (lengthCheck(res)) {
        setParams(result, getItem(res))
      }
      readies.result = true;
      // menuView.value = 'overallResult';
    }).catch(e => {
      console.error(e);
    })
  }


  getMyAbility();

  if (session.value.viceMgrApptYmd != null ) {
    showMessage({
      title: '',
      text: '역량개발평가 대상이 아닙니다.'
    });
  }

  return {
    session,
    isMobile,
    capabilityId,
    currentView,
    readies,
    courses,
    fileDownKey: 'CapabilityEvaluation',
    // 수정
    result,
    elements,
    isMainActive,
    isFileAtchListShow,
  };
}

export const capabilityRouteSetup = () => {
  const route = useRoute();
  const router = useRouter();
  const currentName = computed(() => route.name)
  const currentView = computed(() => route.params.view ? route.params.view : 'result')

  const menus = ref([
    {name: '전체결과', view: 'result'},
    {name: '필수이수학점', view: 'credit'},
    {name: '직무필수과정', view: 'reqsubj'},
    {name: '자격증취득가점', view: 'certificate'},
    {name: ' 연수제도', view: 'system'},
  ]);

  const moveRoute = menu => {
    // router.push(currentName.value === 'MyCapability' ? {params: {view: menu.view}} : {name: 'MyCapability', params: {view: menu.view}}).then(() => {});
    router.push(currentName.value === 'MyAbilityView' ? {params: {view: menu.view}} : {name: 'MyAbilityView', params: {view: menu.view}}).then(() => {});
  }

  return {
    currentName,
    currentView,
    menus,
    moveRoute
  }
}

const expertCodes = ['S1', 'S2', 'S3'];

export const capabilityTotalStatusSetup = (props) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const isExpert = computed(() => expertCodes.includes(session.value.jbgdCd));

  const courseScore = computed(() => {
    if (props.elements.length > 0) {
      const reqScore = props.result.jkgupReqBegin + getGradeScore(props.result, isExpert.value);
      return `${reqScore > 100 ? 100 : Math.floor(reqScore * 100) / 100}점`;
    }
    return '-';
  });

  const licenseScore = computed(() => {
    if (props.result.ficjkgup) {
      if (props.result.ficjkgup.indexOf('L1') > -1 || props.result.ficjkgup.indexOf('L2') > -1) {
        return `${props.result.addscoreLic}점`;
      }
      return '-'
    }
    return '-'
  });

  const totalScore = computed(() => {
    if (props.elements.length > 0) {
      let totalScore;
      if (props.result.ficjkgup.indexOf('L0') > -1) {
        totalScore = props.result.jkgupReqBegin + getGradeScore(props.result, isExpert.value);
      } else {
        totalScore = props.result.jkgupReqBegin + getGradeScore(props.result, isExpert.value) + props.result.addscoreLic;
      }
      return `${totalScore > 100 ? 100 : Math.floor(totalScore * 100) / 100}점`;
    }
    return '-';
  });

  return {
    courseScore,
    licenseScore,
    totalScore
  }

}

export const capabilityCourseStatusSetup = (props) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);
  const isExpert = computed(() => expertCodes.includes(session.value.jbgdCd));

  const isFoundation = computed(() => {
    return props.elements.includes('S');
  });

  const isRequirement = computed(() => {
    return session.value.jbgdCd && session.value.jbgdCd.indexOf('L') === 0 && props.elements.includes('R');
  });

  const requirementText = computed(() => {
    if (isFoundation.value) {
      return 'KB은행실무종합과정 (舊직원실무평가)';
    }
    return '신임과정';
  });

  const beginScore = computed(() => {
    if (props.elements.includes('S') || props.elements.includes('O')) {
      return `${props.result.jkgupReqBegin > 0 ? Math.floor(props.result.jkgupReqBegin * 100) / 100 : props.result.jkgupReqBegin}점`;
    }
    return '-';
  });

  const gradeScore = computed(() => {
    if (props.elements.includes('R') || props.elements.includes('P')) {
      const gradeScore = Math.floor(getGradeScore(props.result, isExpert.value) * 100) / 100;
      return gradeScore > 0 ? `${gradeScore}점` : '-';
    }
    return '-';
  });



  const totalScore = computed(() => {
    if (props.elements.length > 0) {
      let score = props.result.jkgupReqBegin + getGradeScore(props.result, isExpert.value);
      return `${Math.floor(score * 100) / 100}점`;
    }
    return '-';
  });


  return {
    session,
    gradeScore,
    totalScore,
    isFoundation,
    isRequirement,
    requirementText,
    beginScore,
  }
}

export const capabilityLicenseStatusSetup = (props) => {
  const maxLicenseScore = computed(() => {
    let score = 0;
    if (props.result.ficjkgup) {
      if (props.result.ficjkgup.indexOf('L1') > -1) {
        score = 10;
      } else if (props.result.ficjkgup.indexOf('L2') > -1) {
        score = 5;
      }
    }
    return score;
  });


  return {
    maxLicenseScore
  }
}

export const capabilityCreditSetup = (props) => {
  const store = useStore();
  const popupToggle = ref(false);
  const divModalKey = ref("공통");
  const items = reactive({
    S: [],
    R: [],
    O: [],
    P: []
  });
  const reqsubjGroup = reactive([]);
  const gradeItems = computed(() => [...items.P, ...items.R]);
  const guideFile = computed(() => downloadAbilityGuideFiles.filter(x => x.page === 'ability')[0].files);

  // 은실종 이력
  const getAbilityBankList = (key) => {
    store.dispatch(`my/${ACT_GET_ABILITY_BANK_LIST}`, props.result.cdpResultId).then(res => {
      if(lengthCheck(res)){
        items[key] = getItems(res);
      }
    }).catch(e => {
      console.error(e);
    })
  }

  // 신임과정 이력, 직무필수 이력 조회
  const getAbilityRequirementList = (key) => {
    // searchType => R : 직무필수, O : 신임과정
    let pObj,sObj;
    if(key==="R") {
      pObj = {
        name: "공통",
        maxCnt: 2,
        curCnt: 0
      };
      sObj = {
        name: "선택",
        maxCnt: props.result.jikmuReqCountDefault - 2,
        curCnt: 0
      };
    }
    store.dispatch(`my/${ACT_GET_ABILITY_REQUIREMENT_LIST}`, {
      cdpResultId: props.result.cdpResultId,
      params: {searchType: key},
    }).then(res => {

      console.log("lengthCheck(res)", lengthCheck(res))
      if(lengthCheck(res)){
        items[key] = getItems(res);
        //신버전 직무필수 수료현황 update
        if(key==="R"){
          let pCnt = items[key].filter( x=> x.gjCdp.indexOf("공통")>=0 ).length;
          pObj.curCnt = pCnt;
          let sCnt = items[key].filter( x=> x.gjCdp.indexOf("선택")>=0 ).length;
          sObj.curCnt = sCnt;

          reqsubjGroup.push(pObj);
          reqsubjGroup.push(sObj);
          console.log('check', reqsubjGroup);
        }
      }else{
        if(key==="R"){
          reqsubjGroup.push(pObj);
          reqsubjGroup.push(sObj);
        }
      }

    }).catch(err => {
      console.error(err)
    })
  }

  // 승격예비 과정 리스트
  const getAbilityGradeList = (key) => {

    store.dispatch(`my/${ACT_GET_ABILITY_GRADE_LIST}`, props.result.cdpResultId).then(res => {
      if(lengthCheck(res)){
        items[key] = getItems(res);
      }
    }).catch(err => {
      console.error(err)
    })
  }


  const getAbilityList = () => {
    // S: 은실종, R: 직무필수, O: 신임과정, P: 승격예비
    props.elements.forEach(el => {
      if(el === 'S'){
        getAbilityBankList(el);
      }else if(el === 'R' || el === 'O'){
        getAbilityRequirementList(el);
      }else if(el === 'P') {
        getAbilityGradeList(el)
      }
    });
  }


  const showSubjPopup = (divName) => {
    console.log(divName);
    popupToggle.value = true;
    divModalKey.value = divName;
  };

  getAbilityList();

  return {
    items,
    gradeItems,
    downloadFileDownMng,
    guideFile,
    reqsubjGroup,
    popupToggle,
    divModalKey,
    showSubjPopup,

  }
}

export const capabilityReqSubjSetup = (props) => {
  const store = useStore();
  const router = useRouter();
  const popupToggle = ref(false);
  const divModalKey = ref("공통");
  const items = reactive({
    R: [],
  });

  const reqsubjGroup = reactive([]);
  const reqDivSubjList = ref(null);
  const gradeItems = computed(() => [...items.R]);
  const guideFile = computed(() => downloadAbilityGuideFiles.filter(x => x.page === 'ability')[0].files);

  // 신임과정 이력, 직무필수 이력 조회
  const getAbilityRequirementList = (key) => {
    // searchType => R : 직무필수, O : 신임과정
    let pObj,sObj;
    if(key==="R") {
      pObj = {
        name: "공통",
        maxCnt: 2,
        curCnt: 0
      };
      sObj = {
        name: "선택",
        maxCnt: props.result.jikmuReqCountDefault - 2,
        curCnt: 0
      };
    }
    store.dispatch(`my/${ACT_GET_ABILITY_REQUIREMENT_LIST}`, {
      cdpResultId: props.result.cdpResultId,
      params: {searchType: key},
    }).then(res => {

      console.log("lengthCheck(res)", lengthCheck(res))
      if(lengthCheck(res)){
        items[key] = getItems(res);
        //신버전 직무필수 수료현황 update
        if(key==="R"){
          let pCnt = items[key].filter( x=> x.gjCdp.indexOf("공통")>=0 ).length;
          pObj.curCnt = pCnt;
          let sCnt = items[key].filter( x=> x.gjCdp.indexOf("선택")>=0 ).length;
          sObj.curCnt = sCnt;

          reqsubjGroup.push(pObj);
          reqsubjGroup.push(sObj);
          console.log('check', reqsubjGroup);
        }
      }else{
        if(key==="R"){
          reqsubjGroup.push(pObj);
          reqsubjGroup.push(sObj);
        }
      }

    }).catch(err => {
      console.error(err)
    })
  }

  // 직무필수과정 분야별 항목 조회
  const getAbilityReqSubjList = () => {
    let output = [];
    store.dispatch(`my/${ACT_GET_ABILITY_REQSUBJ_LIST}`, {
      cdpResultId: props.result.cdpResultId,
      params: {},
    }).then(res => {

      if(lengthCheck(res)){
        output = getItems(res);
      }
    }).catch(err => {
      console.error(err)
    }).finally(()=>{
          reqDivSubjList.value =  output;
          store.commit(`my/${MUT_SET_ABILITY_REQSUBJ_LIST}`, output);
    }
    );
  }

  getAbilityReqSubjList();

  const showSubjPopup = (divName) => {
    console.log(divName);
    popupToggle.value = true;
    divModalKey.value = divName;
  };

  const showReqSubjListMobile = (divName) =>{
    router.push({name: 'AbilitySubjPopView', params: {courseKey: divName}});
  }
  getAbilityRequirementList("R");


  // onUnmounted(()=> {
  //   store.commit(`my/${MUT_SET_ABILITY_REQSUBJ_LIST}`, []);
  // });

  return {
    items,
    gradeItems,
    downloadFileDownMng,
    guideFile,
    reqsubjGroup,
    popupToggle,
    divModalKey,
    reqDivSubjList,
    showSubjPopup,
    showReqSubjListMobile,

  }
}

export const capabilityBeginTableSetup = (props) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const isDashLine = computed(() => {
    return (props.divCd === 'S' && !(session.value.jbgdCd && session.value.jbgdCd.indexOf('L') === 0));
  });

  const title = computed(() => {
    const titles = [];
    if (props.divCd === 'O') {
      titles.push('신임과정');
    }
    if (props.divCd === 'S') {
      titles.push('KB은행실무종합과정 (舊직원실무평가)');
    }
    return titles.join(' - ');
  });

  const reflectedCount = computed(() => {
    if (props.divCd === 'O') {
      return props.items.length;
    }

    if (props.divCd === 'S') {
      return 2;
    }
    return 0;
  });



  const getSubCategory = subjnm => {
    if (subjnm) {
      if (subjnm.indexOf('의무') > -1) {
        return '의무';
      } else if (subjnm.indexOf('희망') > -1) {
        return '희망';
      }
    }
    return '-'
  }


  return {
    title,
    reflectedCount,
    isDashLine,
    getSubCategory
  }
}

export const capabilityGradeTableSetup = (props) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const transitionalItems = computed(() => {
    const output = [];
    if (props.divCds.includes('R') && props.result.jikmuReqDefCount > 0) {
      const obj = {subjnm: '---', ended: '---', memo: '경과조치에 의한 직무필수인정'};
      for(let i = 0; i < props.result.jikmuReqDefCount; i++){
        output.push(obj);
      }
    }
    return output;
  });

  const reqsubjList = computed(() => {

    const output = [];
    const items = [...props.items];
    //기본 layout 공통1,2,선택1,2 made
    for(let i=0;i<props.result.jikmuReqCountDefault; i++){
      let obj = {subjnm: '---', ended: '---', courseDivNm : '---'};
      if(i<2){
        obj.gjCdp = `공통${i+1}`
      }else{
        obj.gjCdp = `선택${(i-2)+1}`
      }
      output.push(obj);
    }

    for (let item of items) {
      let gjcdp = item.gjCdp;
      let curr = output.findIndex( x=>
          gjcdp.indexOf(x.gjCdp.substring(0, 2))>=0 && x.courseDivNm === "---");
      output.splice(curr,1,item);


    }
    //수료내용 maaping




    return output;
  });

  const isDashLine = drgb => {
     return (drgb === 'R' && !(session.value.jbgdCd && session.value.jbgdCd.indexOf('L') === 0));
  }

  const title = computed(() => {
    const titles = [];
    if (props.divCds.includes('P')) {
      titles.push('승격예비');
    }
    if (props.divCds.includes('R')) {
      titles.push('직무필수과정');
    }
    return titles.join(' ・ ');
  });

  const getDivText = gubun => {
    if (gubun === 'P') return '승격예비과정';
    return '직무필수과정'
  }

  return {
    isDashLine,
    session,
    title,
    transitionalItems,
    getDivText,
    reqsubjList,
  }
}

export const capabilityReqSubjPopupSetup = (props, {emit}) => {
  const store = useStore();
  const session = computed(() => store.state.auth.session);

  const view = reactive({current:"", tabs:[]});

  if(props.divKey=="공통"){
    view.current = '공통A';
    view.tabs = [
      {name: '공통A(자산관리)', key: '공통A'},
      {name: '공통B(기업금융)', key: '공통B'},
    ]
  }else{
    view.current =  '직무필수-선택A';
    view.tabs = [
      {name: '영업점심화(자산관리)', key: '직무필수-선택A'},
      {name: '영업점심화(기업금융)', key: '직무필수-선택B'},
      {name: '경영일반', key: '직무필수-선택C'},
      {name: 'IB/리스크/자본시장/글로벌', key: '직무필수-선택D'},
      {name: '디지털', key: '직무필수-선택E'},
    ]
  }


  const currentTab = computed(() => {
    const target = view.tabs.find(x => x.key === view.current);
    return target ? target : {name: '-'};
  });

  const closeModal = () => {
    emit('update:toggle', false);
  }

  const changeDiv = (key) =>{
    emit('divchange', key);
  }

  const renderItems = computed(() => {
    return props.reqDivSubjList.filter( x => x.courseDivCd==currentTab.value.key);
  });


  return {
    session,
    renderItems,
    view,
    currentTab,
    closeModal,
    changeDiv,
  }
}

export const capabilityLicenseSetup = () => {
  const store = useStore();

  const salesMeta = reactive({
    stockFund: null,
    derivationFund: null,
    realEstateFund: null,
    allInFund: null,

    derivation: null,

    lifeInsurance: null,
    generalInsurance: null,
    thirdInsurance: null,
    variableInsurance: null,

    fundDate: null,
    insuranceDate: null,

    passAnnonYmd: null,
  });

  const etcItems = ref([]);

  const getAbilityLscnList = () => {
    store.dispatch(`my/${ACT_GET_MY_ABILITY_SELL_LSCN}`, {}).then(res => {
      if(lengthCheck(res)){
        setParams(salesMeta, getItem(res));
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const getAbilityEtcLscnList = () => {
    store.dispatch(`my/${ACT_GET_MY_ABILITY_ETC_LSCN_LIST}`, {grd1Cd: '00'}).then(res => {
      if(lengthCheck(res)){
        etcItems.value = getItems(res);
      }
    }).catch(err => {
      console.error(err)
    });
  }

  getAbilityLscnList();
  getAbilityEtcLscnList();

  return {
    salesMeta,
    etcItems
  }
}

export const capabilitySystemSetup = () => {
  const systemRoutes = [
      // '직급별 ・ CDP별 연수체계',
    '역량개발평가'];
  const currentIndex = ref(0);

  return {
    systemRoutes,
    currentIndex
  }
}
